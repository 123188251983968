import React from "react"
import { Link } from "gatsby"
import  logo  from "../../images/logo.svg"
import  wlogo  from "../../images/wlogo.svg"
import styles from "./styles.module.css"

class LoginLayout extends React.Component {

  render() {
    return (
      <>
        <div className="v-100" >		
          <div className={['container-fluid','v-100'].join(' ')}>
            <div className={['row', 'd-flex', 'align-items-stretch'].join(' ')}>
                <div className={['col-md-5','align-items-center','v-100','d-md-flex','d-none'  ,styles.lcontent].join(' ')}>
                    <div className={styles.title_text}>
                        <Link className={styles.mobileLogo} to="/"><img src={logo} alt="MProfit Logo" /></Link>
                        <Link className={styles.mainWlogo} to="/"><img src={wlogo} alt="MProfit Logo" /></Link>
                        <h4>Manage your investments the right way</h4>
                    </div>
                </div>
                <div className={['col-md-5','align-items-center','p-3','d-md-none','d-block'].join(' ')}>
                    <div className={styles.title_text}>
                        <Link className={styles.mobileLogo} to="/"><img src={logo} alt="MProfit Logo" /></Link>
                    </div>
                </div>
                <div className={['col-md-12', 'd-flex', 'align-items-center' , 'pl-3', 'pr-3' , 'v-100',styles.form_area].join(' ')} >
                    <div className={styles.formboxContent}>
                        {this.props.children}
                    </div>
                </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default LoginLayout