import React from "react"
import { navigate, Link } from "gatsby"
import { handleLogin, getUser, logout } from "../../services/auth"
import styles from "./styles.module.css"
import { checkEmail, setUrlReferralCode, setUrlEmailAddr, setUrlExtraFamilies } from "../../services/orderProcessing";
import { connect } from "react-redux"
import { emailInvalid, requiredError } from "../../common/validation";
import LoginFormField from "../../components/loginFormField";
import { Alert } from 'reactstrap';
import LoginLayout from "../../components/loginLayout";
import ProceedButton from "../../components/proceedButton";
import { REQUESTPASSWORDRESET, VALIDATEPASSWORDRESETCODE, SETNEWPASSWORD, FORGOTCUSTOMERID } from "../../services/API";
import { createUrlQueryParams, loginToMProfitCloudWithAffiliateLinkIfExists, processResponse } from "../../common/gFunctions";
import { True_False } from "../../common/constants";
import SEO from "../../components/seo";

const queryString = require('query-string');

class LoginStructureComponent extends React.Component {
  constructor(props) {
    super(props);
    this.props.resetOrderReducer();

    this.passwordInput = React.createRef();
    this.emailInput = React.createRef();

    this.state = {
      email: '',
      password: '',
      mfaOTP: '',
      affiliateUTM: null,
      resetCode: '',
      isModal: false,
      isEmailNew: true,
      useCustomerId: false,
      userCheck: {},
      setPassword: {
        newPassword: '',
        confirmPassword: ''
      },
      resetPasswordEmail: '',
      resetPassword: false,
      resetPasswordStatus: {
        requested: false,
        success: false,
        userNotFound: false,
        failure: false
      },
      dirty: false,
      loginError: false,
      setPasswordError: false,
      proceedLoading: false,
      isValidatePasswordResetMode: false
    }
  }

  componentDidMount() {
    const user = getUser();
    this.checkLoggedIn(user);

    if (this.props.location) {
      const search = queryString.parse(this.props.location.search, {arrayFormat: 'bracket'});
      const email = search.email;
      const mode = search.mode;
      const showExtraFamilies = search.fam;
      var isValidatePasswordResetMode = mode == 'reset';

      var affiliateUTM = search.a && search.a.trim() != '' ? search.a : null;
      if (!search.a) {
        var searchParamsFromStorage = window.localStorage.getItem("urlSearchParams");
        const searchParamsParsed = queryString.parse(searchParamsFromStorage, {arrayFormat: 'bracket'});
        if (searchParamsParsed && searchParamsParsed.a) {
          affiliateUTM = searchParamsParsed.a;
        }
      }
      if (affiliateUTM) {
        this.setState({affiliateUTM});
      }

      if (showExtraFamilies) {
        setUrlExtraFamilies(showExtraFamilies);
      }

      if (email) {
        setUrlEmailAddr(email);

        if (isValidatePasswordResetMode) {
          const resetCode = search.code;
          this.setState({
            email,
            resetCode,
            isValidatePasswordResetMode
          })

          if (resetCode) {
            setTimeout(() => {
              this.handleSubmit();
            })
          }
        } else {
          const password = search.password;

          if (password) {
            this.setState({
              email,
              password,
              isEmailNew: false
            })
          } else {
            const customerId = search.cid;

            if (customerId) {
              this.setState({
                email,
                password: customerId,
                useCustomerId: true,
                isEmailNew: false
              });
            } else {
              this.setState({
                email
              });
            }
          }

          setTimeout(() => {
            this.handleSubmit();
          })
        }
      } else if (isValidatePasswordResetMode) {
        this.setState({isValidatePasswordResetMode});
      }
      setUrlReferralCode(search.referral || search.code);

      window.sessionStorage.setItem('desktopPlanRecommendation', undefined);
    }
  }

  handleUpdate = (e,section=null,isNumber=false) => {
    const value = isNumber ? +e.target.value : (e.target.type === 'checkbox' ? e.target.checked : e.target.value);
    const name = e.target.name;

    if (!section) {
      this.setState({
        [name]: value
      })
    } else {
      this.setState({
        [section]: {
          ...this.state[section],
          [name]: value
        }
      })
    }
  }

  handleResetSubmit = (event=null) => {
    if (event) {
      event.preventDefault();
    }

    var validationError = {
      newPassword: requiredError(this.state.setPassword.newPassword),
      confirmPassword: this.state.setPassword.confirmPassword !== this.state.setPassword.newPassword
    }

    if (Object.values(validationError).includes(true)) {
      this.setState({
        dirty: true,
      })
    } else {
      this.setState({
        dirty: false,
        proceedLoading: true
      });

      var body = {
        ResetCode: this.state.resetCode,
        NewPassword: this.state.setPassword.newPassword,
        EmailAddr: this.state.email
      }

      fetch(SETNEWPASSWORD,{
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body)
      }).then(processResponse).then(({error,response}) => {
        if (error.status<400) {
          this.setState({
            isValidatePasswordResetMode: false,
            showPassword: true,
            resetPassword: false,
            proceedLoading: false,
            loginAlert: 'Login with your new password'
          })
        } else {
          this.setState({
            setPasswordError: true
          })
        }
      })
    }
  }

  handleSubmit = (event=null) => {
    if (event) {
      event.preventDefault();
    }

    if (this.state.isValidatePasswordResetMode) {
      this.setState({proceedLoading: true});
      fetch(VALIDATEPASSWORDRESETCODE,{
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          EmailAddr: this.state.email,
          ResetCode: this.state.resetCode
        })
      }).then(processResponse).then(({error,response}) => {
        this.setState({proceedLoading: false})
        if (error.status >= 400) {
          this.setState({resetError: 'There was an error processing your request'});
        } else {
          if (response.IsSuccess) {
            this.setState({resetError: undefined, resetPassword: true, dirty: false, isValidatePasswordResetMode: false})
          } else {
            this.setState({resetError: response.ErrorMessage});
          }
        }
      });
      return;
    }

    if (this.state.email === "" && this.props.isBuyDesktop) {
      // navigate('/order/product');
    } else {
      // validation
      var validationError = {
        email: requiredError(this.state.email) ,
        password: !this.state.isEmailNew ? requiredError(this.state.password) : false,
        isValidEmail: requiredError(this.state.email) ? false :  emailInvalid(this.state.email), 
      }

      if(Object.values(validationError).includes(true))
      {
            this.setState({
              dirty: true
            })
      }
      else
      {
        this.setState({
          dirty: false,
          proceedLoading: true,
          loginError: false,
          mfaError: undefined,
          isEmailNotFoundError: false
        })
        if (this.state.isEmailNew) {
          this.checkUserEmail(this.state.email).then((userCheck) => {
            const isEmailNew = !userCheck.IsExisting;
            if (userCheck.DirectToBuy && !userCheck.HasCloud && this.props.isBuyDesktop) {
              this.setState({
                proceedLoading: false,
                isEmailNotFoundError: true
              })
              // this.props.setSelectedEmail(this.state.email);
              // navigate('/order/product');
            } else {
              this.setState({isEmailNew, dirty: !this.props.isBuyDesktop && isEmailNew, userCheck, useCustomerId: !userCheck.HasCloud, proceedLoading: false});

              setTimeout(() => {
                if (this.passwordInput.current) {
                  this.passwordInput.current.focus();
                }
              },10)
            }
          });
        } else {
          handleLogin(this.state).then(({error,response}) => {
            if (error.status >= 400) {
              console.log(error,'lgoinError');
              var errorText = error.stack && error.stack.error ? error.stack.error : undefined;

              if (errorText == 'mfaotpsent') {
                this.setState({
                  showMFAOTP: true,
                  proceedLoading: false
                })
              } else if (errorText == 'mfaotpexpired') {
                this.setState({
                  mfaError: 'Code has expired, please request a new one',
                  proceedLoading: false
                })
              } else if (errorText == 'mfaotpused') {
                this.setState({
                  mfaError: 'Code has been used, please request a new one',
                  proceedLoading: false
                })
              } else if (errorText == 'mfaotpinvalid') {
                this.setState({
                  mfaError: 'Incorrect code',
                  proceedLoading: false
                })
              } else {
                this.setState({
                  loginError: true,
                  proceedLoading: false
                })
              }
            } else {
              if (response.resetPassword === True_False.TRUE.toString()) {
                this.setState({
                  resetPassword: true,
                  proceedLoading: false,
                  dirty: false
                })
              } else {
                var redirectUrl = null;
                if (this.props.location && this.props.location.state) {
                  redirectUrl = this.props.location.state.redirectUrl;
                }

                console.log(redirectUrl,'redirectUrl')

                navigate(redirectUrl || this.props.nextPage);
              }
            }
          });
        }
      }
    }
  }

  checkLoggedIn = (user) => {
    if (user) {
      if (user.resetPassword === True_False.TRUE.toString()) {
        logout();
      } else {
        navigate(this.props.nextPage);
      }
    }
  }

  checkUserEmail = (email) => {
    return checkEmail(email).then(({response,error}) => {
      if (error.status < 400) {
        return response;
      }
    })
  }

  clearEmail = () => {
    this.setState({email: '', isEmailNew: true, password: '', mfaOTP: '', showMFAOTP: false, useCustomerId: false, loginError: false});

    setTimeout(() => {
      if (this.emailInput.current) {
        this.emailInput.current.focus();
      }
    },10)
  }

  toggleCustomerId = () => {
    this.setState({useCustomerId: !this.state.useCustomerId});
  }

  requestNewMFACode = () => {
    this.setState({mfaOTP: '', dirty: true}, () => {
      this.setState({dirty: false});
      this.handleSubmit();
    })
  }

  openResetModal = () => {
    this.setState({
      isModal: true,
      resetPasswordEmail: this.state.email,
      resetPasswordStatus: {
        requested: false,
        success: false,
        userNotFound: false,
        failure: false
      }
    })
  }

  requestResetPassword = (event=null) => {
    if (event) {
      event.preventDefault();
    }

    this.setState({
      resetLoading: true
    });

    fetch(createUrlQueryParams(this.state.useCustomerId ? FORGOTCUSTOMERID : REQUESTPASSWORDRESET,{emailAddr:encodeURIComponent(this.state.resetPasswordEmail)}),{
      method: 'POST'
    }).then(processResponse).then(({error,response}) => {
      var success = false, userNotFound = false, failure = false;
      if (error.status < 400) {
        success = true;
      } else if (error.status === 400) {
        userNotFound = true;
      } else {
        failure = true;
      }

      this.setState({resetLoading: false, resetPasswordStatus: {
        requested: true,
        success,
        userNotFound,
        failure
      }});
    })

  }

  backToLogin = () => {
    this.setState({isValidatePasswordResetMode: false, resetCode: ''})
  }

  render() {
    const { showProceed, headingText, submitText, isTrial, trialSignUpLink, showCloudLoginLink=false, seoFields } = this.props;
    const { showMFAOTP, isValidatePasswordResetMode } = this.state;
    const showPassword = !this.state.isEmailNew;
    const userCheck = this.state.userCheck;
    const passwordField = !this.state.useCustomerId ? 'Password' : 'Customer ID';
    const invalidEmail = !showPassword && this.state.dirty && !userCheck.IsExisting && !this.props.isBuyDesktop

    const showCustomerIdToggle = userCheck.HasDesktop && userCheck.HasCloud && !this.state.isEmailNew;

    var resetPasswordModalTitle = this.state.useCustomerId ? 'Forgot your Customer ID?' : 'Reset your password';
    var resetPasswordBody = "Enter the email address associated with your account, and we'll send you an email with " + (this.state.useCustomerId ? " your Customer ID." : "a link to reset your password.");
    var resetButtonText = this.state.useCustomerId ? "Send me my Customer ID" : "Reset my password";
    const resetPasswordStatus = this.state.resetPasswordStatus;

    if (resetPasswordStatus.requested) {
      resetPasswordModalTitle = resetPasswordStatus.success ? (this.state.useCustomerId ? 'Customer ID sent' : 'Password reset link sent') : (resetPasswordStatus.userNotFound ? 'Email not found' : 'Error resetting password');
      resetPasswordBody = resetPasswordStatus.success ? (this.state.useCustomerId ? 'Please check your inbox (or spam folder) for the email with your Customer ID.' : 'Please check your inbox (or spam folder) for the password reset link.') : (resetPasswordStatus.userNotFound ? 'Please enter the email associated with your MProfit Account' : 'Please contact MProfit Support at support@mprofit.in');
    }

    return (
      <>
        <SEO {...seoFields}/>
        <LoginLayout>
          {
            this.state.resetPassword ?
            <>
            <div className={[styles.f_title].join(' ')}>
              <h2 className={[styles.modalTitle].join(' ')}>Set New Password</h2>
            </div>
            <form className={[styles.form].join(" ")} autoComplete="off" method="post" action="login" acceptCharset="UTF-8" id="login-nav"
                onSubmit={this.handleResetSubmit}>
                <LoginFormField fieldName="newPassword" key="newPassword" label={'New Password'} sectionName={'setPassword'} sectionValue={this.state} handleInputChange={this.handleUpdate} optional={false} dirty={this.state.dirty} inputType={'password'}/>
                <LoginFormField fieldName="confirmPassword" key="confirmPassword" label={'Re-enter Password'} sectionName={'setPassword'} sectionValue={this.state} handleInputChange={this.handleUpdate} optional={false} dirty={this.state.dirty} inputType={'password'}/>
                <Alert color="danger" fade={true} isOpen={this.state.dirty || this.state.setPasswordError}>{this.state.setPasswordError ? 'Error setting password. Please refresh and try again' : 'Passwords do not match. Please check and submit again.'}</Alert>                
              <div className="form-group">
                <ProceedButton onClick={()=>{}} loading={this.state.proceedLoading} buttonText={'Save'} type="submit"/>
                {/* <button type="submit" className={['btn','btn-primary','btn-block',styles.btn].join(' ')}>{submitText}</button> */}
              </div>

            </form>
            </>
            :
            <>
            <div className={[styles.f_title].join(' ')}>
              <h2 className={[styles.modalTitle].join(' ')}>{headingText}</h2>
            </div>
            { showProceed && <p style={{marginTop:'20px', fontWeight: 600}}>Login to your MProfit account</p> }

            <form className={[styles.form].join(" ")} autoComplete="off" method="post" action="login" acceptCharset="UTF-8" id="login-nav"
                onSubmit={this.handleSubmit}>
              <LoginFormField fieldName="email" key="email" ref={this.emailInput} label="Email" sectionName={null} sectionValue={this.state} handleInputChange={this.handleUpdate} optional={false} dirty={this.state.dirty} disabled={showPassword} inputType={'text'} customValidation={'email'} checkValidationAfterSubmit={true} showEdit={!this.state.isEmailNew} onEditClick={this.clearEmail}/>
              <Alert color="danger" fade={true} isOpen={invalidEmail}>{'You have entered an incorrect email.'}</Alert>
              {
                isValidatePasswordResetMode ?
                <>
                  <LoginFormField fieldName="resetCode" key="resetCode" ref={this.passwordInput} label={'Password Reset Code'} sectionName={null} sectionValue={this.state} handleInputChange={this.handleUpdate} optional={false} dirty={this.state.dirty} inputType={'password'}/>
                  {
                    this.state.resetError == null ?
                    <Alert color="success" fade={true} isOpen={!this.state.proceedLoading}>{'Enter the password reset code sent to your email'}</Alert>
                    :
                    <Alert color="danger" fade={true} isOpen={true}>{this.state.resetError}</Alert>
                  }
                  <div className={styles.helpBlock}><span onClick={this.backToLogin}>Back to Login</span></div>
                </>
                :
                (
                showPassword ?
                <>
                  {
                    !showMFAOTP ?
                    <>
                    <LoginFormField fieldName="password" key="password" ref={this.passwordInput} label={passwordField} sectionName={null} sectionValue={this.state} handleInputChange={this.handleUpdate} optional={false} dirty={this.state.dirty} inputType={'password'}/>
                    <Alert color="danger" fade={true} isOpen={this.state.loginError}>{'You have entered an incorrect email, ' + passwordField.toLowerCase() + ', or both.'}</Alert>
                    {
                      !this.state.loginError && this.state.loginAlert &&
                      <Alert color="success" fade={true} isOpen={true}>{this.state.loginAlert}</Alert>
                    }
                    </>
                    :
                    <>
                    <LoginFormField fieldName="mfaOTP" key="mfaOTP" label={'Enter your 2FA OTP'} sectionName={null} sectionValue={this.state} handleInputChange={this.handleUpdate} optional={false} dirty={this.state.dirty}/>
                    {
                      this.state.mfaError == null ?
                      <Alert color="success" fade={true} isOpen={!this.state.proceedLoading}>{'Your 2FA OTP has been sent'}</Alert>
                      :
                      <Alert color="danger" fade={true} isOpen={true}>{this.state.mfaError}</Alert>
                    }
                    </>
                  }
                  {
                    showMFAOTP ?
                    <div className={styles.helpBlock}><span onClick={this.requestNewMFACode}>Request new code</span></div>
                    :
                    <div className={styles.helpBlock}><span onClick={this.openResetModal}>{'Forgot '+passwordField+'?'}</span></div>
                  }
                </>
                :
                <>
                  {
                    this.state.isEmailNotFoundError &&
                    <Alert color="danger" fade={true} isOpen={true}>{'Please sign-up by clicking the button below and then proceed to buy'}</Alert>
                  }
                </>
                )
              }
              <div className="form-group">
                <ProceedButton onClick={()=>{}} loading={this.state.proceedLoading} buttonText={submitText} type="submit"/>
                {/* <button type="submit" className={['btn','btn-primary','btn-block',styles.btn].join(' ')}>{submitText}</button> */}
              </div>

            </form>
            { isTrial ?
              <div className={styles.bottom}>
                Don't have an MProfit account yet? <Link to={trialSignUpLink}>Click here</Link>
              </div>
              :
              <div className={styles.bottom}>
                Don't have MProfit? <Link to="/sign-up">&nbsp;Try for free now</Link>
              </div>
            }
            {
              showCloudLoginLink && this.state.isEmailNew &&
              <div className={'btn-section '+styles.btnSection}>
                <a onClick={loginToMProfitCloudWithAffiliateLinkIfExists} className={['btn', 'btn-light',styles.btn].join(' ')}>Login to MProfit Cloud instead</a>
              </div>
            }
            {
              showCustomerIdToggle &&
              <div className={'btn-section '+styles.btnSection}>
                <span className={['btn', 'btn-light',styles.btn].join(' ')} onClick={this.toggleCustomerId}>{`Prefer to use your ${this.state.useCustomerId ? 'Password' : 'Customer ID'} instead?`}</span>
              </div>
            }
            {/* {
              otherUserText &&
              <div className={'btn-section '+styles.btnSection}>
                <Link className={['btn', 'btn-light',styles.btn].join(' ')} to={otherUserLink}>{otherUserText}</Link>
              </div>
            } */}
            {
            <div className={[this.state.isModal ? 'd-block' :'' , 'modal', styles.forget_password].join(' ')} id="myModal">
              <div className={["modal-dialog"].join(" ")}>
                <div className={["modal-content"].join(" ")}>
                  <div className={["modal-header", styles.modal_header].join(" ")}>
                    <button type="button" className={[styles.close,"close"].join(" ")} data-dismiss="modal" onClick={() => this.setState({isModal: false})}>&times;</button>
                  </div>
                  <div className="modal-body">
                    <form className="sign_up_form" onSubmit={this.requestResetPassword}>
                      <h2 className="modal-title mb-3 mb-md-0">{resetPasswordModalTitle}</h2>
                      <p>{resetPasswordBody}</p>
                      <div className="form-group">
                        <LoginFormField fieldName="resetPasswordEmail" label="Email" sectionName={null} sectionValue={this.state} handleInputChange={this.handleUpdate} optional={false} dirty={this.state.dirty} disabled={this.state.resetLoading || this.state.resetPasswordStatus.success} inputType={'text'} customValidation={'email'} checkValidationAfterSubmit={false}/>
                      </div>
                    </form>
                  </div>
                  <div className="modal-footer">
                      <div className={['col-sm-12', 'col-md-5', 'order-md-1', 'text-left', 'd-none', 'd-md-block'].join(' ')} onClick={() => this.setState({isModal: false, isValidatePasswordResetMode: true, resetError: undefined})}>
                        <span data-dismiss="modal" className="backtologin modal_back_link" ><i className={['fa', 'fa-angle-left'].join(' ')} aria-hidden="true"></i>
                          Enter Reset Code</span>
                      </div>
                      <div className={['col-sm-12', 'col-md-7', 'text-right','p-0', 'order-md-2'].join(' ')}> 
                      {
                        !this.state.resetPasswordStatus.success && 
                          <ProceedButton buttonText={resetButtonText} borderRadius={5} loading={this.state.resetLoading} onClick={this.requestResetPassword}/>
                      }
                      </div>
                  </div>
                </div>
              </div>
            </div>
          }
          </>
        }
        </LoginLayout>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setSelectedEmail: (email) => dispatch({type: 'SET_SELECTED_EMAIL', data: email}),
    resetOrderReducer: () => dispatch({type: 'RESET_ORDER_REDUCER'})
  }
}

const LoginStructure = connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginStructureComponent)  

export default LoginStructure