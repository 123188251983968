import React from "react"
import styles from "./styles.module.css"
import { emailInvalid, phoneNumberInvalid, requiredError } from "../../common/validation";
import DropDown from "../../shared/dropDown";

class LoginFormField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dirty: false,
      propsDirty: this.props.dirty
    }

    this.inputField = React.createRef();
  }

  focus = () => {
    if (this.inputField.current) {
      this.inputField.current.focus();
    }
  }

  handleInputChange = (event, type) => {
    this.setState({
      dirty: true
    });
    this.props.handleInputChange(event, type);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.dirty === false && state.propsDirty === true && !props.dontResetDirty) {
      return {
        ...state,
        dirty: false,
        propsDirty: false
      }
    }

    return {
      ...state,
      propsDirty: props.dirty
    };
  }

  render() {
    const {fieldName, label, sectionName, sectionValue, customValidation, inputType="text", options = [], optional = false, disabled=false, checkValidationAfterSubmit=false, showEdit=false, onEditClick=()=>{}} = this.props;

    const value = sectionName ? sectionValue[sectionName][fieldName]: (fieldName ? sectionValue[fieldName] : sectionValue);

    var errorText = '';

    var validationClass = "";
    
    const dirty = (this.state.dirty && !checkValidationAfterSubmit) || this.props.dirty;

    if (!optional && dirty && requiredError(value,inputType)) {
      validationClass = styles.error;
    }
    else
    {
      validationClass = "";
    }

    if (customValidation === 'email' && !optional && dirty && emailInvalid(value)) {
      validationClass = styles.error;
      errorText = 'Please enter a valid email';
    } else if (customValidation === 'phone' && dirty && phoneNumberInvalid(value,optional)) {
      validationClass = styles.error;
      errorText = 'Please enter a valid number';
    }

    return (
      <>
        <div className={["form-group"].join(' ')}>
          {
            (() => {
              switch(inputType) {
                default:
                case 'password':
                case 'text':
                  return (
                    <>
                    <input ref={this.inputField} id={sectionName+'-'+fieldName} type={inputType} name={fieldName} className={["form-control",styles.formControl,validationClass].join(" ")} placeholder={label} value={value} onChange={(event)=>this.handleInputChange(event,sectionName)} disabled={disabled}/>
                    {
                      showEdit &&
                      <span className={["fa fa-pencil",styles.editIcon].join(" ")} onClick={onEditClick}></span>
                    }
                    </>
                  )
                case 'dropdown':
                  return (
                    <DropDown title={idToText(sectionValue[sectionName][fieldName], options)} mprdropDown={true} classes={["form-control", styles.formControl, "d-flex", "align-items-center", "justify-content-between", "cursor-pointer", validationClass].join(" ")} data={options} callBack={(item)=>this.handleInputChange({target:{type:'select',name:fieldName,value:item.id || item}}, sectionName,true)}/>

                      // <select className={["form-control", styles.formControl, validationClass].join(" ")} name={fieldName} value={value} onChange={(event)=>this.handleInputChange(event,sectionName)} disabled={disabled}>
                      //   {options.map((item,index) => <option value={item.id || item} key={item.id || item} disabled={item.disabled}>{item.text || item}</option>)}  
                      // </select>
                  )
              }
            })()
          }
            { errorText && this.props.dirty && <label className={styles.errorText}>{errorText}</label> }
        </div>
      </>
    )
  }
}

const idToText = (filedName,option) => {
  var tempObj = option.filter(obj => obj.id === filedName);
  return tempObj.length > 0 ? tempObj[0].text : filedName;
}

export default LoginFormField